import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout, { Content } from './components/Layout';
import ContentNavigation from './components/Navigation/Content';
import HeroHeader from './components/heroheader';
import formatSlug from './lib/slugFormatter';
import TeaserContainer, { TeaserItem } from './components/Teaser';

export const query = graphql`
  query CategoriesQuery {
    allStrapiProgrammes {
      edges {
        node {
          Title
          Intro
          strapiId
          Key_Visual {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 430
                height: 302
                placeholder: DOMINANT_COLOR
              )
            }
          }
          Category {
            id
            Title
          }
          Country {
            Title
          }
        }
      }
    }
    allStrapiCategories {
      edges {
        node {
          Title
          Intro
          strapiId
          Key_Visual {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 430
                height: 302
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`;

const categories = ({ data, pageContext, location }) => {
  const categories = data.allStrapiCategories.edges.map(elem => elem.node);
  const programmes = data.allStrapiProgrammes.edges.map(elem => elem.node);

  const navigationData = categories
    .map(category => {
      return {
        id: category.id,
        title: category.Title,
        to: formatSlug([category.Title]),
        programmes: programmes
          .filter(
            programme =>
              programme.Category && programme.Category.id === category.strapiId
          )
          .map(programme => {
            return {
              id: programme.id,
              title: programme.Title,
              to: formatSlug([
                programme.Category.Title,
                programme.Country ? programme.Country.Title : 'world',
                programme.Title,
              ]),
              thumbnail: programme.Key_Visual
                ? programme.Key_Visual
                : null,
            };
          }),
      };
    })
    .filter(link => link.programmes.length);

  return (
    <Layout title={pageContext.Title}>
      <HeroHeader
        title={pageContext.Title}
        backgroundUrl="/images/destinations.jpg"
      />
      <ContentNavigation
        links={navigationData}
        currentPathName={location.pathname}
      />
      <Content>
        {navigationData.map(link => {
          return (
            <div key={link.strapiId}>
              <h2>
                <Link to={link.to}>{link.title}</Link>
              </h2>
              <TeaserContainer>
                {link.programmes.map(subLink => (
                  <TeaserItem
                    key={subLink.strapiId}
                    link={subLink.to}
                    title={subLink.title}
                    thumbnail={subLink.thumbnail}
                    description={subLink.description}
                  />
                ))}
              </TeaserContainer>
            </div>
          );
        })}
      </Content>
    </Layout>
  );
};

export default categories;
